const CorpInfo = () => (
    <div id="corpInfo">
        <div>
            <h1>Formul<span id="ai" className="">ai</span>c</h1>
            <p>Cloud Development</p>
        </div>
    </div>
);

export default CorpInfo;
